import request from '@/plugins/request'

//留资列表
export function userApplyList(data = {}){
  return request({
    url: `/api/user/apply/userApplyList`,
    method: 'POST',
    data: data,
  })
}

//详情
export function fetchUser(itemId){
  return request({
    url: '/api/user/apply/getApply?id='+itemId,
    method: 'GET',
    params: {},
  })
}

// 联系确认
export function apply(data = {}){
  return request({
    url: '/api/user/apply/applyAffirm',
    method: 'POST',
    data: data,
  })
}

export default{
  //留资列表
  userApplyList,
  fetchUser,
  apply,
}