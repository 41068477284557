<template>
  <el-dialog 
    title="详情"
    :visible="visible"
    :close-on-click-modal="true"
    :before-close="handleClose"
    width="600px"
  >
    <el-form :model="formData" ref="form" label-width="150px" size="mini" v-loading="loading">
      <el-form-item label="用户名">
        <div>{{formData.name}}</div>
      </el-form-item>
      <el-form-item label="联系电话">
        <div>{{formData.phone}}</div>
      </el-form-item>
      <el-form-item label="城市">
        <div>{{formData.city}}</div>
      </el-form-item>
      <el-form-item label="留资时间">
        <div>{{formData.createDate}}</div>
      </el-form-item>
      <el-form-item label="客户状态">
        <div>{{formData.type == 1 ? '已联系' : formData.type == 2 ? '未联系' : ''}}</div>
      </el-form-item>
      <el-form-item label="负责人">
        <div>{{formData.director}}</div>
      </el-form-item>
      <el-form-item label="联系时间">
        <div>{{formData.contacts}}</div>
      </el-form-item>
      <el-form-item label="合作意向">
        <div>{{formData.intention}}</div>
      </el-form-item>
      <el-form-item label="联系备注">
        <div>{{formData.contactsRemark}}</div>
      </el-form-item>
    </el-form>
    <gl-flex>
      <el-button @click="handleClose">关闭</el-button>
    </gl-flex>
  </el-dialog>
</template>

<script>
import {fetchUser} from '@/api/backendall/userApply.js'
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    itemId: {
      type: String,
      default: ''
    },
  },
  data(){
    return{
      formData: {},
      loading: false,
    }
  },
  watch: {
    visible: {
      handler: function(newVal){
        newVal && this.itemId && this.getUser()
      }
    }
  },
  methods: {
    getUser: async function(){
      this.loading = true
      try{
        const res = await fetchUser(this.itemId)
        this.formData = res.data
        this.loading = false
      }catch(e){
        this.loading = false
      }
    },
    handleClose: function(){
      this.$emit('update:visible', false)
      this.formData = {}
      this.$refs.form.resetFields()
    },
  },
}
</script>

<style>

</style>