<template>
  <div class="userApply">
    <gl-title title="留资管理"></gl-title>
    <gl-page-header>
      <el-form :model="mFormData" ref="listForm" :inline="true" size="mini">
        <el-form-item prop="name" label="用户名">
          <gl-list-search v-model="mFormData.name" placeholder="用户名"></gl-list-search>
        </el-form-item>
        <el-form-item prop="type" label="客户状态">
          <gl-option v-model="mFormData.type" :list="options"></gl-option>
        </el-form-item>
        <el-form-item>
          <el-button  type="primary" icon="el-icon-search" @click="mReload"> 搜索 </el-button>
          <el-button icon="el-icon-refresh" @click="mResetForm('listForm')"> 重置 </el-button>
        </el-form-item>
      </el-form>
    </gl-page-header>
    <gl-card v-loading="mLoading">
      <el-table :data="mTableData" style="width: 100%">
        <el-table-column type="index" :index="mIndexMethod" label="序号" align="center" min-width="30"></el-table-column>
        <el-table-column prop="name" label="姓名"></el-table-column>
        <el-table-column prop="phone" label="联系电话" width="100"></el-table-column>
        <el-table-column prop="city" label="城市"></el-table-column>
        <el-table-column prop="createDate" label="留资时间" show-overflow-tooltip></el-table-column>
        <el-table-column prop="type" label="客户状态">
          <template slot-scope="{row}">
            <span v-if="row.type == 1">已联系</span>
            <span v-else>未联系</span>
          </template>
        </el-table-column>
        <el-table-column prop="director" label="负责人"></el-table-column>
        <el-table-column prop="contacts" label="联系时间" show-overflow-tooltip></el-table-column>
        <el-table-column prop="intention" label="合作意向"></el-table-column>
        <el-table-column label="操作" width="160px" fixed="right">
          <template slot-scope="scope">
            <el-button  v-if="scope.row.type !== 1" type="text" @click="mClickAction('mUpdateVisible', scope.row.id)"> 联系确认 </el-button>
            <el-button type="text" @click="mClickAction('mPreviewVisible', scope.row.id)"> 查看 </el-button>
          </template>
        </el-table-column>
      </el-table>
      <gl-pagination
        :total="mTotal"
        :current-page="mPageNum"
        @currentChange="mHandleCurrentChange"
        @sizeChange="mHandleSizeChange">
      </gl-pagination>
    </gl-card>
    <preview :itemId="mItemId" :visible.sync="mPreviewVisible"/>
    <verifyDialog :itemId="mItemId" :visible.sync="mUpdateVisible" @reload="mReload"/>
  </div>
</template>

<script>
import {userApplyList} from '@/api/backendall/userApply.js'
import ListMixin from '@/mixins/list.mixin.js'
import preview from './preview.vue'
import verifyDialog from './verifyDialog.vue'
export default {
  name: 'userApply',
  mixins: [ListMixin],
  components: {preview, verifyDialog},
  data(){
    return{
      options: [
        {name: '全部', value: ''},
        {name: '已联系', value: '1'},
        {name: '未联系', value: '2'},
      ],
    }
  },
  mounted(){
    this.mGetListFun = userApplyList
    this.mGetList()
  },
}
</script>

<style>

</style>