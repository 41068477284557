<template>
  <el-dialog 
    title="修改"
    :visible="visible"
    :close-on-click-modal="true"
    :before-close="handleClose"
    width="600px"
  >
    <gl-wrap right="30px">
      <el-form :model="formData" ref="form" label-width="150px" size="mini" v-loading="loading">
        <el-form-item prop="type" label="客户状态" verify>
          <gl-option v-model="formData.type" :list="options"></gl-option>
        </el-form-item>
        <el-form-item prop="director" label="负责人" verify>
          <el-input v-model="formData.director" placeholder="请输入负责人"></el-input>
        </el-form-item>
        <el-form-item prop="contactsRemark" label="联系备份" verify>
          <el-input v-model="formData.contactsRemark" placeholder="请输入联系备注"></el-input>
        </el-form-item>
      </el-form>
    </gl-wrap>
    <gl-flex>
      <el-button type="primary" @click="onSubmit" >确认</el-button>
      <el-button @click="handleClose" >关闭</el-button>
    </gl-flex>
  </el-dialog>
</template>

<script>
import {apply} from '@/api/backendall/userApply.js'
export default {
  name: 'verifyDialog',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    itemId: {
      type: String,
      default: ''
    },
  },
  data(){
    return{
      options: [
        {name: '已联系', value: '1'},
        {name: '未联系', value: '2'},
      ],
      formData: {},
      loading: false,
    }
  },
  methods: {
    onSubmit(){
      this.$refs.form.validate(async valid => {
        if(!valid) return false
        this.loading = true
        try{
          const params = {
            id: this.itemId,
            ...this.formData,
          }
          const res = await apply(params)
          this.loading = false
          this.$message({type: 'success', message: '操作成功'})
          this.$emit('reload')
          this.handleClose()
        }catch(e){
          this.loading = false
        }
      })
    },
    handleClose(){
      this.$emit('update:visible', false)
      this.formData = {}
      this.$refs.form.resetFields()
    },
  },
}
</script>

<style>

</style>